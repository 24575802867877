import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, MainContainer, TopPointsContainer, TopPointsCard, Heading, FitnessActivityCard } from './style';
import LeaderbordNameHeader from '../NameHeader/leaderboardNameHeader';
import BuddiesData from './topBuddiesData';
import FitnessActivityData from './fitnessActivityData';
import { getUserId, getTopFitnessLeaderboard, getTopSocialUsers, getTopFitnessUsers, getTopWellnessUsers, getTopUserTotalPoints, getCompanyTotalTeamPoints, getTotalTeamUsersPoints, fetchTeamIcons, fetchCompanyTeamsCount, fetchCompanyTeamAndMyTeamFlags, getUserLeaderboardPoints } from '../../redux/actions';
import { Waiting } from '../../components';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import LazyImage from '../common/LazyImage/LazyImage';

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBuddiesPoints: "1",
      companyId: props.companyInfo.id || 1,
      userLimit: 10,
      pageNumber: 1,
      temp: 1,
      cardHeader: true
    }
  }

  componentDidMount() {
    const { companyId, userLimit, pageNumber } = this.state;
    const { getTopFitnessLeaderboard, getTopUserTotalPoints, getUserLeaderboardPoints } = this.props;
    getTopFitnessLeaderboard(companyId, pageNumber, userLimit);
    getTopUserTotalPoints(companyId, pageNumber, userLimit);
    this.props.getUserId()
    this.props.getCompanyTotalTeamPoints(companyId,pageNumber,userLimit)
    this.props.fetchTeamIcons()
    this.props.fetchCompanyTeamsCount(companyId)
    this.props.fetchCompanyTeamAndMyTeamFlags(companyId)
    getUserLeaderboardPoints()
  }

  componentDidUpdate() {
    if(this.props.wellnessCount && this.props.wellnessCount.company_team && this.state.cardHeader ){
      this.setState({
        activeBuddiesPoints: "9",
        cardHeader: false
      })
    }
  }

  callTopBuddiesPointsAPI  = () =>{
    const { companyId, userLimit, pageNumber, activeBuddiesPoints } = this.state;
    const {  getTopSocialUsers, getTopFitnessUsers, getTopWellnessUsers, getTopUserTotalPoints, getCompanyTotalTeamPoints, getTotalTeamUsersPoints } = this.props;
    if (activeBuddiesPoints === "1") {
      getTopUserTotalPoints(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "2") {
      getTopFitnessUsers(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "3") {
      getTopSocialUsers(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "4") {
      getTopWellnessUsers(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "9") {
      getCompanyTotalTeamPoints(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "8") {
      getTotalTeamUsersPoints();
    }
  }

  buddiesPointUpdate = (value) => {
    this.setState({
      activeBuddiesPoints: value,
      pageNumber: 1
    }, () => {
      this.callTopBuddiesPointsAPI();
    })
  }

  leaderboardLimit = () => {
    const { companyId, userLimit, temp, pageNumber, activeBuddiesPoints } = this.state;
    const { getTopFitnessLeaderboard, getTopSocialUsers, getTopFitnessUsers, getTopWellnessUsers, getTopUserTotalPoints, getCompanyTotalTeamPoints, getTotalTeamUsersPoints } = this.props;
    this.setState({ pageNumber: this.state.pageNumber + temp });
    if (activeBuddiesPoints === "1") {
      getTopUserTotalPoints(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "2") {
      getTopFitnessUsers(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "3") {
      getTopSocialUsers(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "4") {
      getTopWellnessUsers(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "9") {
      getCompanyTotalTeamPoints(companyId, pageNumber, userLimit);
    }
    else if (activeBuddiesPoints === "8") {
      getTotalTeamUsersPoints();
    }
    else if (activeBuddiesPoints > "4")
      getTopFitnessLeaderboard(companyId, pageNumber, userLimit);
  }

  updateLimit = () => {
    const { temp } = this.state;
    this.setState({ pageNumber: this.state.pageNumber + temp });
  }

  buddiesPointsCards = () => {
    const { activeBuddiesPoints } = this.state;
    const { t } = this.props;
    return (
      <TopPointsContainer>
        {this.props.wellnessCount && Object.keys(this.props.wellnessCount).length && this.props.wellnessCount.company_team && <TopPointsCard color={activeBuddiesPoints === "9" ? "#fff" : '#F8A887'} background={activeBuddiesPoints === "9" ? '#F8A887' : '#fff'} border={activeBuddiesPoints === "9" ? '' : '1px solid #F8A887'} onClick={() => this.buddiesPointUpdate("9")}>
          <div>
            {activeBuddiesPoints !== "9" ? <LazyImage src={ImageUrl + "/images/topTeams.png"} alt="Total Teams" /> : <LazyImage src={ImageUrl + "/images/topWellnessTeams.png"} alt="Total Teams" />}
            <div>{t("Top Company Wellness Teams")}</div>
          </div>
          <div>
            {activeBuddiesPoints === "9" ? <div /> : null}
          </div>
        </TopPointsCard>}
        {this.props.wellnessCount && Object.keys(this.props.wellnessCount).length && this.props.wellnessCount.my_team && <TopPointsCard color={activeBuddiesPoints === "8" ? "#fff" : '#2509e5d9;'} background={activeBuddiesPoints === "8" ? 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)' : '#fff'} border={activeBuddiesPoints === "8" ? '' : '1px solid #2509e5d9;'} onClick={() => this.buddiesPointUpdate("8")}>
          <div>
            {activeBuddiesPoints !== "8" ? <LazyImage src={ImageUrl + "/images/showteamIcon2.png"} alt="Total Teams" /> : <LazyImage src={ImageUrl + "/images/showteamicon.png"} alt="Total Teams" />}
            <div>{t("My Wellness Teams")}</div>
          </div>
          <div>
            {activeBuddiesPoints === "8" ? <div /> : null}
          </div>
        </TopPointsCard>}
        <TopPointsCard color={activeBuddiesPoints === "1" ? "#fff" : 'rgba(105, 194, 255, 1)'} background={activeBuddiesPoints === "1" ? 'rgba(105, 194, 255, 1)' : '#fff'} border={activeBuddiesPoints === "1" ? '' : '1px solid rgba(105, 194, 255, 1)'} onClick={() => this.buddiesPointUpdate("1")}>
          <div>
            {activeBuddiesPoints !== "1" ? <LazyImage src={ImageUrl + "/images/Leaderboard/totalPointsColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/totalPoints.png"} alt="Total Points" />}
            <div>{t("Top Buddies Total Points")}</div>
          </div>
          <div>
            {activeBuddiesPoints === "1" ? <div /> : null}
          </div>
        </TopPointsCard>
        <TopPointsCard color={activeBuddiesPoints === "2" ? "#fff" : 'rgba(253, 113, 117, 1)'} background={activeBuddiesPoints === "2" ? 'rgba(253, 113, 117, 1)' : '#fff'} border={activeBuddiesPoints === "2" ? '' : '1px solid rgba(253, 113, 117, 1)'} onClick={() => this.buddiesPointUpdate("2")}>
          <div>
            {activeBuddiesPoints !== "2" ? <LazyImage src={ImageUrl + "/images/Leaderboard/fitnessColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/fitnessPoints.png"} alt="Fitness Points" />}
            <div>{t("Top Buddies Fitness Points")}</div>
          </div>
          <div>
            {activeBuddiesPoints === "2" ? <div /> : null}
          </div>
        </TopPointsCard>
        <TopPointsCard color={activeBuddiesPoints === "3" ? "#fff" : 'rgba(246, 180, 121, 1)'} background={activeBuddiesPoints === "3" ? 'rgba(246, 180, 121, 1)' : '#fff'} border={activeBuddiesPoints === "3" ? '' : '1px solid rgba(246, 180, 121, 1)'} onClick={() => this.buddiesPointUpdate("3")}>
          <div>
            {activeBuddiesPoints !== "3" ? <LazyImage src={ImageUrl + "/images/Leaderboard/socialPointsColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/socialPoints.png"} alt="Socials Points" />}
            <div>{t("Top Buddies Social Points")}</div>
          </div>
          <div>
            {activeBuddiesPoints === "3" ? <div /> : null}
          </div>
        </TopPointsCard>
        <TopPointsCard color={activeBuddiesPoints === "4" ? "#fff" : 'rgba(159, 201, 137, 1)'} background={activeBuddiesPoints === "4" ? 'rgba(159, 201, 137, 1)' : '#fff'} border={activeBuddiesPoints === "4" ? '' : '1px solid rgba(159, 201, 137, 1)'} onClick={() => this.buddiesPointUpdate("4")}>
          <div>
            {activeBuddiesPoints !== "4" ? <LazyImage src={ImageUrl + "/images/Leaderboard/wellnessColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/wellnessPoints.png"} alt="wellnessPoints" />}
            <div>{t("Top Buddies Wellness Points")}</div>
          </div>
          <div>
            {activeBuddiesPoints === "4" ? <div /> : null}
          </div>
        </TopPointsCard>
      </TopPointsContainer>
    )
  }

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  fitnessLeaderboard = () => {
    const { activeBuddiesPoints } = this.state;
    const { distanceLeaderboard,  stepsLeaderboard, caloriesLeaderboard, t } = this.props;
    if (isNull(distanceLeaderboard) || isUndefined(distanceLeaderboard) || isNull(caloriesLeaderboard) || isUndefined(caloriesLeaderboard) ||  isNull(stepsLeaderboard) || isUndefined(stepsLeaderboard)) {
      return <Waiting />
    }

    return (
      <TopPointsContainer marginTop="25px">
        <Heading>{t("Fitness Activity Leaderboard")}</Heading>
        <FitnessActivityCard onClick={() => this.buddiesPointUpdate("5")} color={activeBuddiesPoints === "5" ? "#fff" : '#32DBC6'} background={activeBuddiesPoints === "5" ? 'linear-gradient(90deg, #00B1BC 0%, #75D9A3 100%)' : '#fff'} border={activeBuddiesPoints === "5" ? '' : '1px solid #32DBC6'} profileBorder={activeBuddiesPoints === "5" ? '3px solid #fff' : '3px solid #32DBC6'}>
          <div>
            {activeBuddiesPoints !== "5" ? <LazyImage src={ImageUrl + "/images/Leaderboard/stepColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/step.png"} alt="Fitness Points" />}
            <div>
              <div>{t("Steps Completed")}</div>
              <div>
                <div>
                  {stepsLeaderboard && !isUndefined(stepsLeaderboard[0]) && !isNull(stepsLeaderboard[0]) && !isUndefined(stepsLeaderboard[0].profile_image) && !isNull(stepsLeaderboard[0].profile_image) && <LazyImage onError={this.fallBack} src={`${ImageUrl}/${stepsLeaderboard[0].profile_image}`} alt="Profile" />}
                  {stepsLeaderboard && !isUndefined(stepsLeaderboard[1]) && !isNull(stepsLeaderboard[1]) && !isUndefined(stepsLeaderboard[1].profile_image) && !isNull(stepsLeaderboard[1].profile_image) && <LazyImage onError={this.fallBack} src={`${ImageUrl}/${stepsLeaderboard[1].profile_image}`} alt="Profile" />}
                  {stepsLeaderboard && !isUndefined(stepsLeaderboard[2]) && !isNull(stepsLeaderboard[2]) && !isUndefined(stepsLeaderboard[2].profile_image) && !isNull(stepsLeaderboard[2].profile_image) && <LazyImage onError={this.fallBack} src={`${ImageUrl}/${stepsLeaderboard[2].profile_image}`} alt="Profile" />}
                </div>
              </div>
            </div>
          </div>
          <div>
            {activeBuddiesPoints === "5" ? <div /> : null}
          </div>
        </FitnessActivityCard>
        <FitnessActivityCard onClick={() => this.buddiesPointUpdate("6")} color={activeBuddiesPoints === "6" ? "#fff" : 'rgba(246,180,121,1)'} background={activeBuddiesPoints === "6" ? 'linear-gradient(90deg, #F6B479 0%, #FF9434 100%)' : '#fff'} border={activeBuddiesPoints === "6" ? '' : '1px solid rgba(246,180,121,1)'} profileBorder={activeBuddiesPoints === "6" ? '3px solid #fff' : '3px solid rgba(246,180,121,1)'}>
          <div>
            {activeBuddiesPoints !== "6" ? <LazyImage src={ImageUrl + "/images/Leaderboard/caloryColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/calory.png"} alt="Fitness Points" />}
            <div>
              <div>{t("Calories Burned")}</div>
              <div>
                <div>
                  {caloriesLeaderboard[0] && !isUndefined(caloriesLeaderboard[0]) && <img onError={this.fallBack} src={`${ImageUrl}/${caloriesLeaderboard[0].profile_image}`} alt="Profile" />}
                  {caloriesLeaderboard[1] && !isUndefined(caloriesLeaderboard[1]) && <img onError={this.fallBack} src={`${ImageUrl}/${caloriesLeaderboard[1].profile_image}`} alt="Profile" />}
                  {caloriesLeaderboard[2] && !isUndefined(caloriesLeaderboard[2]) && <img onError={this.fallBack} src={`${ImageUrl}/${caloriesLeaderboard[2].profile_image}`} alt="Profile" />}
                </div>
              </div>
            </div>
          </div>
          <div>
            {activeBuddiesPoints === "6" ? <div /> : null}
          </div>
        </FitnessActivityCard>
        <FitnessActivityCard onClick={() => this.buddiesPointUpdate("7")} color={activeBuddiesPoints === "7" ? "#fff" : 'rgba(105,194,255,1)'} background={activeBuddiesPoints === "7" ? 'linear-gradient(90deg, #52A8ED 0%, #8AD3F7 100%)' : '#fff'} border={activeBuddiesPoints === "7" ? '' : '1px solid rgba(105,194,255,1)'} profileBorder={activeBuddiesPoints === "7" ? '3px solid #fff' : '3px solid rgba(105,194,255,1)'}>
          <div>
            {activeBuddiesPoints !== "7" ? <LazyImage src={ImageUrl + "/images/Leaderboard/locationColor.png"} alt="Total Points" /> : <LazyImage src={ImageUrl + "/images/Leaderboard/location.png"} alt="Fitness Points" />}
            <div>
              <div>{t("Distance Traveled")}</div>
              <div>
                <div>
                  {distanceLeaderboard[0] && !isUndefined(distanceLeaderboard[0]) && <img onError={this.fallBack} src={`${ImageUrl}/${distanceLeaderboard[0].profile_image}`} alt="Profile" />}
                  {distanceLeaderboard[1] && !isUndefined(distanceLeaderboard[1]) && <img onError={this.fallBack} src={`${ImageUrl}/${distanceLeaderboard[1].profile_image}`} alt="Profile" />}
                  {distanceLeaderboard[2] && !isUndefined(distanceLeaderboard[2]) && <img onError={this.fallBack} src={`${ImageUrl}/${distanceLeaderboard[2].profile_image}`} alt="Profile" />}
                </div>
              </div>
            </div>
          </div>
          <div>
            {activeBuddiesPoints === "7" ? <div /> : null}
          </div>
        </FitnessActivityCard>
      </TopPointsContainer>
    )
  }

  render() {
    const { history, fitnessLeaderboard, socialUserData, fitnessUserData, wellnessUserData, topUsersTotalPoints, getTopSocialUsers, getTopFitnessUsers, getTopWellnessUsers, getTopUserTotalPoints, distanceLeaderboard, getTopFitnessLeaderboard, stepsLeaderboard, caloriesLeaderboard, getCompanyTotalTeamPoints, companyTeamPoints, getTotalTeamUsersPoints, teamIconsData, fetchTeamIcons } = this.props;
    const { activeBuddiesPoints, companyId, pageNumber, userLimit } = this.state;
    if (isNull(fitnessLeaderboard) || isUndefined(fitnessLeaderboard) || isNull(distanceLeaderboard) || isUndefined(distanceLeaderboard) || isNull(caloriesLeaderboard) || isUndefined(caloriesLeaderboard) ||  isNull(stepsLeaderboard) || isUndefined(stepsLeaderboard)) {
      return <Waiting />
    }
    return (
      <Layout>
        <div>
          <LeaderbordNameHeader companyId={companyId} />
          <div>
            <MainContainer style={{display: "flex"}}>
              <div style={{width: "32%"}}>
                {this.buddiesPointsCards()}
                {this.props.companyBranding?.company_branding?.show_education ? this.fitnessLeaderboard():null}
              </div>
              <div>
                {(activeBuddiesPoints <= "4" || activeBuddiesPoints == "8" || activeBuddiesPoints == "9")  ? <BuddiesData history={history}
                  topUsersTotalPoints={topUsersTotalPoints}
                  wellnessUserData={wellnessUserData}
                  fitnessUserData={fitnessUserData}
                  socialUserData={socialUserData}
                  activeBuddiesPoints={activeBuddiesPoints}
                  companyTeamPoints={companyTeamPoints}
                  teamIconsData={teamIconsData}
                  leaderboardLimit={this.leaderboardLimit}
                  pageNumber={pageNumber}
                  getTopSocialUsers={getTopSocialUsers}
                  getTopFitnessUsers={getTopFitnessUsers}
                  getTopWellnessUsers={getTopWellnessUsers}
                  getTopUserTotalPoints={getTopUserTotalPoints}
                  getCompanyTotalTeamPoints={getCompanyTotalTeamPoints}
                  getTotalTeamUsersPoints={getTotalTeamUsersPoints}
                  fetchTeamIcons={fetchTeamIcons}
                  userLimit={userLimit}
                  companyId={companyId}
                  updateLimit={this.updateLimit}
                /> :
                  <FitnessActivityData
                    history={history}
                    fitnessLeaderboard={fitnessLeaderboard}
                    activeBuddiesPoints={activeBuddiesPoints}
                    leaderboardLimit={this.leaderboardLimit}
                    userLimit={userLimit}
                    companyId={companyId}
                    updateLimit={this.updateLimit}
                    pageNumber={pageNumber}
                    getTopFitnessLeaderboard={getTopFitnessLeaderboard}
                    distanceLeaderboard={distanceLeaderboard}
                    stepsLeaderboard={stepsLeaderboard}
                    caloriesLeaderboard={caloriesLeaderboard}
                  />}
              </div>
            </MainContainer>
          </div>
        </div>
      </Layout>)
  }
}

Leaderboard.defaultProps = {
  companyInfo: { id: 1, name: '' }
}

Leaderboard.propTypes = {
  history: PropTypes.object.isRequired,
  companyInfo: PropTypes.object,
  fitnessLeaderboard: PropTypes.object,
  wellnessUserData: PropTypes.object,
  fitnessUserData: PropTypes.object,
  socialUserData: PropTypes.object,
  topUsersTotalPoints: PropTypes.object,
  companyTeamPoints: PropTypes.object,
  teamUsersPoints: PropTypes.array,
  teamIconsData: PropTypes.object,
  teamsCount: PropTypes.array,
  wellnessCount: PropTypes.object,
  getUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  getTopFitnessLeaderboard: PropTypes.func,
  getTopSocialUsers: PropTypes.func,
  getTopFitnessUsers: PropTypes.func,
  getTopWellnessUsers: PropTypes.func,
  getTopUserTotalPoints: PropTypes.func,
  getCompanyTotalTeamPoints: PropTypes.func,
  getTotalTeamUsersPoints: PropTypes.func,
  fetchCompanyTeamsCount:PropTypes.func,
  fetchCompanyTeamAndMyTeamFlags: PropTypes.func,
  fetchTeamIcons: PropTypes.func,
  distanceLeaderboard: PropTypes.object,
  stepsLeaderboard: PropTypes.object,
  caloriesLeaderboard: PropTypes.object,
  t: PropTypes.func,
  getUserLeaderboardPoints: PropTypes.func,
  user: PropTypes.array,
  companyBranding:PropTypes.object
};

const mapStateToProps = (state) => ({
  fitnessLeaderboard: state.companyDashboard.fitnessLeaderboard,
  wellnessUserData: state.companyDashboard.wellnessUserData,
  fitnessUserData: state.companyDashboard.fitnessUserData,
  socialUserData: state.companyDashboard.socialUserData,
  topUsersTotalPoints: state.companyDashboard.topUsersTotalPoints,
  companyTeamPoints: state.companyDashboard.companyTeamPoints,
  teamUsersPoints: state.companyDashboard.teamUsersPoints,
  userId: state.profileData.userId,
  distanceLeaderboard: state.companyDashboard.distanceLeaderboard,
  stepsLeaderboard: state.companyDashboard.stepsLeaderboard,
  caloriesLeaderboard: state.companyDashboard.caloriesLeaderboard,
  teamIconsData: state.companyDashboard.teamIconsData,
  teamsCount: state.companyDashboard.teamsCount,
  wellnessCount: state.companyDashboard.wellnessCount,
  user: state.profileData.user,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getUserId: () => dispatch(getUserId()),
  getTopFitnessLeaderboard: (id, pageNumber, limit) => dispatch(getTopFitnessLeaderboard(id, pageNumber, limit)),
  getTopSocialUsers: (id, pageNumber, limit) => dispatch(getTopSocialUsers(id, pageNumber, limit)),
  getTopFitnessUsers: (id, pageNumber, limit) => dispatch(getTopFitnessUsers(id, pageNumber, limit)),
  getTopWellnessUsers: (id, pageNumber, limit) => dispatch(getTopWellnessUsers(id, pageNumber, limit)),
  getTopUserTotalPoints: (id, pageNumber, limit) => dispatch(getTopUserTotalPoints(id, pageNumber, limit)),
  getCompanyTotalTeamPoints: (id, pageNumber, limit) => dispatch(getCompanyTotalTeamPoints(id, pageNumber, limit)),
  getTotalTeamUsersPoints: () => dispatch(getTotalTeamUsersPoints()),
  fetchTeamIcons: () => dispatch(fetchTeamIcons()),
  fetchCompanyTeamsCount: (companyId) => dispatch(fetchCompanyTeamsCount(companyId)),
  fetchCompanyTeamAndMyTeamFlags: (companyId) => dispatch(fetchCompanyTeamAndMyTeamFlags(companyId)),
  getUserLeaderboardPoints: () => dispatch(getUserLeaderboardPoints()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Leaderboard));
